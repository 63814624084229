// extracted by mini-css-extract-plugin
export var btn = "categoryProductCard-module--btn--c8295";
export var button = "categoryProductCard-module--button--ca7d8";
export var buyButton = "categoryProductCard-module--buy-button--37028";
export var cafeFormIntro = "categoryProductCard-module--cafe-form-intro--d096e";
export var card = "categoryProductCard-module--card--73368";
export var cardImage = "categoryProductCard-module--card-image--34df7";
export var cardTitle = "categoryProductCard-module--card-title--23b4a";
export var cardWrapper = "categoryProductCard-module--card-wrapper--76e45";
export var highlight = "categoryProductCard-module--highlight--83924";
export var rating = "categoryProductCard-module--rating--0612a";
export var redButton = "categoryProductCard-module--red-button--9a595";
export var redTransparentButton = "categoryProductCard-module--red-transparent-button--d05e4";
export var transparentButton = "categoryProductCard-module--transparent-button--6ac65";