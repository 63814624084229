import React, { useState, useContext, useEffect } from "react"
import * as styles from "./sectionOverviewGrid.module.scss"
import { FilterContext } from "../../utils/context"
import FilterGroup from "../FilterGroup/FilterGroup"
import CategoryProductCard from "../CategoryProductCard/CategoryProductCard"
import { RichText } from "../Shared/RichText/RichText"
import { useDictionaryQuery } from "../../fragments/hooks/useDictionaryQuery"
import { useLocale } from "../../fragments/hooks/useLocale"

const SectionOverviewGrid = ({ filterGroups, productGrid, description }) => {
  const [gridItems, setGridItems] = useState(null)
  const [allProducts] = useState(productGrid.products ? productGrid.products : [])
  const [showAll, setShowAll] = useState(false)
  // const { loadMoreProducts } = useDictionaryQuery()
  const { showMoreProducts } = useDictionaryQuery()
  const locale = useLocale()
  const {
    segmentFilter,
    benefitsFilter,
    certificationsFilter,
    flavorFilter,
    collectionsFilter,
  } = useContext(FilterContext)

  useEffect(() => {
    const prod = [...allProducts]
    filterByCollections(prod)
  }, [
    segmentFilter,
    certificationsFilter,
    benefitsFilter,
    flavorFilter,
    collectionsFilter,
  ])

  useEffect(() => {
    if (showAll) {
      setTimeout(() => {
        if (PriceSpider) {
          PriceSpider.rebind();
        }
      }, 0)
    }
  }, [showAll])

  useEffect(() =>{
    setTimeout(() => {
        if (PriceSpider) {
            PriceSpider.rebind();
        }
      }, 0)
}, [gridItems])

  const getItemsFromFilter = (items: any, filterGroup: any) => {
    return items.filter((item: any) => {
      let matched = false
      if (item.filters) {
        item.filters.map((filter: any) => {
          if (filterGroup.includes(filter.filterTitle && filter.filterTitle)) {
            matched = true
            return false
          }
        })
      }
      return matched
    })
  }
  const filterBySegment = (array: any) => {
    if (segmentFilter.length > 0) {
      const filterProdBySegment = getItemsFromFilter(array, segmentFilter)
      return filterByTeaBenefit(filterProdBySegment)
    } else {
      return filterByTeaBenefit(array)
    }
  }

  const filterByTeaBenefit = (array: any) => {
    if (benefitsFilter.length > 0) {
      const filteredProdsByTeaBenefit = getItemsFromFilter(
        array,
        benefitsFilter
      )
      return filterByFlavor(filteredProdsByTeaBenefit)
    } else {
      return filterByFlavor(array)
    }
  }

  const filterByFlavor = (array: any) => {
    if (flavorFilter.length > 0) {
      const filteredProdsByFlavor = getItemsFromFilter(array, flavorFilter)
      return filterByCertificationsFilter(filteredProdsByFlavor)
    } else {
      return filterByCertificationsFilter(array)
    }
  }

  const filterByCertificationsFilter = (array: any) => {
    if (certificationsFilter.length > 0) {
      const filterProdByCertificationsFilter = getItemsFromFilter(
        array,
        certificationsFilter
      )
      return createFilteredProducts(filterProdByCertificationsFilter)
    } else {
      return createFilteredProducts(array)
    }
  }

  const filterByCollections = (array: any) => {
    if (collectionsFilter.length > 0) {
      const filteredProdsByCollection = getItemsFromFilter(
        array,
        collectionsFilter
      )
      return filterBySegment(filteredProdsByCollection)
    } else {
      return filterBySegment(array)
    }
  }

  const createFilteredProducts = async (array: any) => {
    const amountOfActiveFilters = segmentFilter.concat(
      segmentFilter,
      certificationsFilter,
      benefitsFilter,
      flavorFilter,
      collectionsFilter
    ).length
    setGridItems(array)
  }

  return (
    <div className="container container-lg">
      <div className={styles.wrapper}>
        <div className={styles.filter}>
          <FilterGroup filterGroups={filterGroups} />
        </div>
        
        <div className={styles.contentWrapper}>
          {description && <div className={styles.desc}>
            <RichText content={description} />
          </div>}
          { allProducts.length > 0 ? <> <div className={styles.productWrapper}>
            { gridItems?.map((product: any, id: number) => {
              return showAll ? (
                <CategoryProductCard data={product} key = {id} />
              ) : id < 9 ? (
                <CategoryProductCard data={product} key = {id}/>
              ) : null
            }) }
          </div>
          <div
            className={styles.buttonContainer}
            onClick={() => {
              setShowAll(true)
            }}
          >
            <button
              className={`${styles.loadMore} ${
                (showAll || gridItems?.length < 9) && styles.displayNone
              }`}
            >
              {showMoreProducts}
            </button>
          </div> </> : <p className={styles.notFound}> No Product Found </p>}
        </div>
      </div>
    </div>
  )
}

export default SectionOverviewGrid
