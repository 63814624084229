import React, { useEffect } from 'react'
import * as styles from './categoryProductCard.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import { useLocalPath } from '../hooks/useLocalPath'
import { useLocale } from '../../fragments/hooks/useLocale'
const CategoryProductCard = ({data}) => {
    const {slug, thumbnailImage, thumbnailTitle, sku} = data
    const locale = useLocale()
    // useEffect(() =>{
    //     const script = document.createElement('script')
    //     script.innerHTML = ` if(PriceSpider) PriceSpider.rebind();`
    //     document.body.appendChild(script)
    //     return () =>{document.body.removeChild(script)}
    // })
    const ratings = (locale) =>{
        
        switch(locale){
            case 'en-CA':
                return (
                    <>
                        <div className={styles.rating}>
                            <div data-bv-show="inline_rating" data-bv-product-id={sku}></div>
                        </div>
                        <button className={styles.buyButton}>
                            Buy Online
                        </button>
                    </>
                )
            case 'fr-CA':
                return(
                    <>
                        <div className={styles.rating}>
                            <div data-bv-show="inline_rating" data-bv-product-id={sku}></div>
                        </div>
                        <button className={styles.buyButton}>
                            ACHETER
                        </button>
                    </>
                )
            default:
                return(
                    <>
                        <div className={styles.rating}>
                            <div data-bv-show="inline_rating" data-bv-product-id={sku}></div>
                        </div>
                        <div className={styles.btn}>
                            <div className={`ps-widget ${styles.button} `} ps-sku={sku}></div>
                        </div>
                    </>
                )
        }
    }
    return (
        <Link to = {useLocalPath(slug)} className={styles.cardWrapper}>
            <div className={styles.card}>
                <div className={styles.cardImage}>
                    <GatsbyImage
                        image={thumbnailImage.gatsbyImageData}
                        alt={`${thumbnailImage.title} image`}
                        loading="lazy"
                    />
                </div>
                <div>
                    <div className={styles.cardTitle}>
                        {thumbnailTitle}
                    </div>
                    {/* <div className={styles.rating}>
                        <div data-bv-show="inline_rating" data-bv-product-id={sku}></div>
                    </div>
                    <div className={styles.btn}>
                        <div className={`ps-widget `} ps-sku={sku}></div>
                    </div> */}
                    {/* <div className={styles.btn}>
                        <div className={`ps-widget $ `} ps-sku={sku}></div>
                    </div> */}
                    {/* <button className={styles.buyButton}>
                        Buy Online
                    </button> */}
                   
                </div>
                {ratings(locale)}
            </div>
        </Link>
    )
}

export default CategoryProductCard