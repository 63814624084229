import React from "react"
import { graphql } from "gatsby"
import HeroComponent from "../components/HeroComponent/HeroComponent"
import SectionOverviewGrid from "../components/SectionOverviewGrid/SectionOverviewGrid"
import Seo from "../components/seo"
import { useUrl } from "../fragments/hooks/useUrl"
import Breadcrum from "../components/Breadcrum/Breadcrum"
const ProductsCategory = ({
    location,
    pageContext,
    data: {
        contentfulPageProductsCategory: {
            heroBanner,
            productGrid,
            seoDescription,
            seoTitle,
            slug,
            filterGroups,
            description,
            breadcrum,
            thumbnailText
        },
        allContentfulPageProductsCategory: {
            nodes
        }
    }
}) => {
   // // console.log(heroImage, "j");
   const lang = useUrl()
  return (
    <section id = 'product-category'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}/>
        {breadcrum && <Breadcrum data ={breadcrum} title = {thumbnailText}/>}
        <HeroComponent data = {heroBanner} />
        <SectionOverviewGrid 
          filterGroups = {filterGroups}
          productGrid = {productGrid}
          description = {description}
          />
    </section>
  )
}

export const CategoryProductsQuery = graphql`
  query CategoryProducts($id: String!, $contentful_id: String!) {
    contentfulPageProductsCategory(id: { eq: $id }) {
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      breadcrum {
        title
        slug
      }
      thumbnailText
      thumbnailImage {
        gatsbyImageData
        title
      }
      heroBanner {
        heroImage {
          gatsbyImageData
          title
        }
        heroImageMobile {
          title
          gatsbyImageData
        }
      }
      description {
        raw
      }
      filterGroups {
        name
        filterGroupTitle
        filterType
        filterItems {
          filterTitle
        }
      }
      productGrid {
        type
        products {
            ... on ContentfulPageProduct{
                slug
                thumbnailTitle
                sku
                filters {
                  filterTitle
                }
                thumbnailImage {
                    gatsbyImageData
                    title
                }
            }
        }
      }
    }
    allContentfulPageProductsCategory(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`
export default ProductsCategory
